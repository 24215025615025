import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { HomeIcon } from "@heroicons/react/solid";
import { CameraIcon } from "@heroicons/react/solid";

import { GatsbyImage } from "gatsby-plugin-image";
import ContentfulRichTech from "../components/ContentfulRichText";
import Newsletter from "../components/Newsletter";
import SEO from "../components/SEO";

const SinglePersonTemplate = ({ person }) => {
  return (
    <Layout>
      <SEO title= {person.name} slug= {person.name.toLowerCase().replaceAll(" ", "").replaceAll("'", "-").replaceAll(":", "-")}/>
      {/* HERO */}
      <div className="hidden lg:block relative bg-e-blue overflow-hidden h-[350px]">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1920&q=60&&sat=-10"
            alt=""
          />
          {/* <div
            className="absolute inset-0 bg-e-blue mix-blend-multiply"
            aria-hidden="true"
          /> */}
          <svg
            width="925"
            height="366"
            viewBox="0 0 925 366"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-0 top-0"
          >
            <path
              d="M344.5 0.327271L0 365.327H925V0.327271H344.5Z"
              fill="#1D71B8"
              fillOpacity="0.4"
            />
          </svg>
        </div>
        <div className="relative max-w-7xl mx-auto pt-[310px] px-4 flex justify-end items-end">
          <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <div>
                  <Link
                    to="/"
                    className="text-white hover:text-e-blue transition"
                  >
                    <HomeIcon
                      className="flex-shrink-0 h-6 w-6"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <Link
                    to="/team"
                    className="ml-4 text-xl font-medium text-white hover:text-e-blue transition"
                  >
                    Team
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <Link
                    to={`/${person.name.replace(" ", "-").toLowerCase()}`}
                    className="ml-4 text-xl font-medium text-white hover:text-e-blue transition"
                  >
                    {person.name}
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* PAGE */}
      <div className="mt-12 lg:mt-2 bg-white overflow-hidden ">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
          <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
            <div className="relative">
              <h3 className=" mb-1 text-3xl leading-8 font-extrabold tracking-tight text-gray-600 sm:text-4xl">
                {person.name}
              </h3>
              <svg
                width="350"
                height="44"
                viewBox="0 0 350 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute -z-2"
              >
                <path d="M1 1H350" stroke="#1D71B8" strokeLinecap="round" />
                <path
                  d="M134.902 43L163.728 0.999992"
                  stroke="#1D71B8"
                  strokeLinecap="round"
                />
              </svg>
              <div className="flex flex-row items-center">
                <h2 className=" mt-4 text-sm text-gray-600 font-semibold tracking-wide uppercase w-[160px] max-w-[160px]">
                  {person.role}
                </h2>
                <span className="inline-flex  mt-2 justify-center sm:justify-start z-10">
                  <a className="ml-3 text-e-blue hover:text-gray-600 cursor-pointer transition-all" target="blank" href= "https://au.linkedin.com/company/edge-legal-aus">
                    <svg
                      fill="currentColor"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="0"
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="none"
                        d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                      ></path>
                      <circle cx="4" cy="4" r="2" stroke="none"></circle>
                    </svg>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <svg
                className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div className="relative text-base mx-auto max-w-prose lg:max-w-[500px] shadow-lg shadow-e-yellow">
                <figure>
                  <div className="aspect-square">
                    <GatsbyImage
                      className=" object-cover object-center"
                      image={person.image.gatsbyImageData}
                      alt={person.name}
                      width={1184}
                      height={1184}
                    />
                  </div>
                </figure>
              </div>
              <div className=" p-8 mx-auto max-w-[500px] bg-e-blue mt-12 lg:mt-24">
                <h2 className="text-e-yellow text-2xl font-medium pb-2">
                  Contact {person.name.split(" ")[0]}
                </h2>
                <div className="mb-4">
                  <h3 className="text-white font-medium text-lg">
                    Call {person.name.split(" ")[0]}
                  </h3>
                  <a
                    href={`tel:${person.phone}`}
                    className="text-gray-300 hover:underline"
                  >
                    {person.phone}
                  </a>
                </div>
                <div className="">
                  <h3 className="text-white font-medium text-lg">
                    Email {person.name.split(" ")[0]}
                  </h3>
                  <a
                    href={`mailto:${person.email}`}
                    className="text-gray-300 hover:underline"
                  >
                    {person.email}
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="text-base max-w-prose mx-auto lg:max-w-none">
                <ContentfulRichTech richText={person.bio} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Newsletter />
    </Layout>
  );
};

export default SinglePersonTemplate;
