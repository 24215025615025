import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import SinglePerson from '../templates/SinglePerson'

const SingleTeam = ({data}) => {

  const person = data.contentfulPerson


  return (
    <SinglePerson person = {person}/>
  )
}

export const query = graphql`
  query getSinglePerson($name: String){
    contentfulPerson(name:{eq: $name}) {
      id
      name 
      role
      bio {
        raw
      }
      image {
        gatsbyImageData(placeholder:BLURRED)
      }
      phone
      email
    }
  }
`

export default SingleTeam